$default-font: "roboto-example", sans-serif;
$darkblue: #18567e;
$blue: #2980b9;
$accent-light: #3498db;
$accent-dark: #18567e;
$text: #1f1f1f;
$white: #ffffff;
$gray: #969696;
$darkgray: #797979;
$light-gray: #d6d6d6;

$orange: #ff8100;
$light-blue: #3bb1ff;

$hover_gray: #f8f8f8;
$red: #e74c3c;
$yellow: #d4ae16;
$texthover: #f5f9ff;
$green: #1ba254;
$darkgreen: #278b52;
$gray-border: 1px solid #eeeeee;
$text-table: #797979;
$icon_arr: (
  "settings",
  "tie",
  "add-user",
  "warning",
  "observation",
  "login",
  "arrow-up",
  "bad",
  "loader",
  "calendar",
  "cancel",
  "caret-down",
  "chain",
  "conversation",
  "empty",
  "doc",
  "logo",
  "edit",
  "faq",
  "good",
  "hat",
  "help",
  "info",
  "internet",
  "man",
  "check",
  "open",
  "plus",
  "ruble",
  "search",
  "letter",
  "time",
  "users-questions",
  "waiting",
  "answers",
  "star",
  "star-filled",
  "accounts",
  "refresh",
  "close-preloader",
  "close-button",
  "operator"
);

//Для блока
$border-radius: 23px;
$block-padding: 20px;
$block-width: 300px;
$block-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);

$border-dark: 1px solid $accent-dark;
$border-light: 1px solid $accent-light;
$border-gray: 0.5px solid $gray;
// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"

$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

$gutter: $grid-gutter-width;

$button-width: 230px;
