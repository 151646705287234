.sidenav---navicon---3gCRo .circle {
  font-weight: bold;
  color: #20638f;
  line-height: 20px;
  position: absolute;
  margin-left: -11px;
  margin-top: -22px;
  text-align: right;
  width: 20px;
  &.red {
    color: #e74c3c;
    margin-top: -5px;
  }
}

.sidenav---navicon---3gCRo .circle-container {
  display: flex;
  flex-direction: column;
}

.summary .leftBlock {
  display: flex;
  flex-direction: column;
  max-width: 78px;
  width: 78px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.summary .leftBlock .status-text {
  font-size: 14px;
  color: $gray;
  margin-top: 13px;
  line-height: 15px;
}

$bottom-indent: 40px;

.question-for-user {
  // width: 200px;
  position: fixed;
  left: 0;
  right: 0;
  top: $bottom-indent;
  display: flex;
  justify-content: center;
  z-index: 100000000000;
  transition: all 500ms ease-in;
  animation: 2s ease-out 0s 1 alternate question_block_animation_1;
  .question-block {
    .question {
      font-weight: bold;
      margin-bottom: 10px;
    }
    button {
      outline: none;
      border: none;
      padding: 10px 30px;
      cursor: pointer;
      background-color: $white;
      border-radius: 4px;
      margin: 0 7px;
      transition: 400ms ease-in-out;

      // width: 50%;
    }
    .yes {
      background-color: $green;
      color: $white;

      box-shadow: 0 0 60px 1px rgba(242, 255, 0, 0.3);
      &:hover {
        background-color: #10b556;
      }
    }
    .no {
      // padding: 10px 10px;
      box-shadow: 0 0 60px 1px rgba(0, 0, 0, 0.062);
      &:hover {
        background-color: #f1f1f1;
      }
      background-color: $white;
    }
  }
}

@keyframes question_block_animation_1 {
  from {
    top: -100px;
  }
  to {
    top: $bottom-indent;
  }
}
