@import "vars";

/*==========  Desktop First  ==========*/

// ≥1200px
@include media-breakpoint-down(lg) {
  .footer {
    & > div {
      flex-direction: column !important;
      align-items: center;
    }
  }
}

// ≥992px
@include media-breakpoint-down(md) {
}

// ≥768px
@include media-breakpoint-down(sm) {
}

// ≥576px
// Стили исключительно для телефонов
// @include media-breakpoint-down(xs) {
@include media-breakpoint-down(sm) {
  .sidenav---navicon---3gCRo .circle {
    font-weight: bold;
    color: #20638f;
    line-height: 20px;
    position: absolute;
    margin-left: -11px;
    margin-top: -22px;
    text-align: right;
    width: 20px;
    &.red {
      color: #e74c3c;
      margin-top: -5px;
    }
  }

  .sidenav---navicon---3gCRo .circle-container {
    display: flex;
    flex-direction: column;
  }

  .summary .leftBlock {
    display: flex;
    flex-direction: column;
    max-width: 78px;
    width: 78px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .summary .leftBlock .status-text {
    font-size: 14px;
    color: $gray;
    margin-top: 13px;
    line-height: 15px;
  }

  // $bottom-indent: 40px;

  // .question-for-user {
  //   bottom: $bottom-indent !important;
  //   animation: 2s ease-out 0s 1 alternate question_block_animation_2;
  // }


  .f-el {
    font-size: 12pt !important;
  }
  .Dialog,
  .Questions {
    h1 {
      // border: none !important;
    }
  }
  .Title {
    .h3 {
      margin: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .AllDialogs,
  .FAQs {
    .css-1pahdxg-control {
      box-shadow: none !important;
      border-color: $blue !important;
      &:active,
      &:focus {
        border-color: transparent !important;
      }
    }
    .notFound {
      margin-top: 10px;
    }
    .css-yk16xz-control {
      border-color: $darkblue;
      outline-color: transparent !important;
    }
    .categories {
      border-radius: 0 !important;
      box-shadow: none;
      border-color: $darkblue;
      * {
        border-radius: 0 !important;
      }
      & > * {
        border-left: 0;
        border-right: 0;
      }
    }
    h1 {
      // margin-bottom: 0 !important;
      // border-bottom: none !important;
    }
    .Search {
      border-radius: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
    .DialogsTable {
      margin-top: 0;
      .summary {
        &:first-child {
          // border-top: none;
        }
      }
    }
  }
  .m-title {
    font-size: 14pt !important;
    font-weight: bold !important;
    border-bottom: 1px solid #d4d4d4 !important;
    width: 100% !important;
    text-align: center;
    justify-content: center;
    a {
      justify-content: center;
      .caret-down {
        height: 7px;
      }
    }
    padding-bottom: 10px !important;
  }
  .margin-negative-10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .m-fz-14 {
    font-size: 14pt !important;
  }
  .RadioGroup {
    display: -moz-inline-grid;
    display: inline-grid;
  }
  .mobile-w-100 {
    width: 100% !important;
    max-width: unset !important;
  }
  .mobile-margin {
    margin: 0 10px 0 10px !important;
  }
  .m-m-t-15 {
    margin-top: 15px !important;
  }
  .mobile-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .footer {
    z-index: 1000 !important;
  }
  .sidenav---collapsed---LQDEv {
    left: -64px !important;
    .SideNavToggle {
      &.d-md-none {
        left: 64px !important;
        box-shadow: -10px -10px 56px #eee;
        background: white;
        span {
          background-color: #2980b9 !important;
          opacity: 1 !important;
        }
      }
    }
  }

  .header-bar {
    padding-left: 64px !important;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
    & .title {
      font-size: 14pt !important;
      line-height: 1.3 !important;
    }

    * {
      // display: flex !important;
      align-items: center !important;
      font-size: 10pt !important;
    }
    .right-header-block {
      margin-left: auto;
    }
  }

  .TitleRow {
    flex-wrap: wrap;
    .Search {
      border-color: $darkblue !important;
      input {
        font-size: 11pt !important;
        &::placeholder {
          color: $text;
        }
      }
      .circle {
        // border-color: $darkblue !important;
        left: -1px !important;
        // &:hover {
        //   background-color: $darkblue !important;
        // }
        // display: none;
      }
    }
  }
  main {
    padding-left: 0 !important;
    padding-right: 0 !important;
    & > div {
      padding-right: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      margin-left: 0 !important;
    }
  }
  .without-border {
    border: none !important;
  }
  .FormForQuotedMessage {
    a {
      margin-top: 5px !important;
      text-align: center;
    }
  }
}
.FiltersBlock {
  margin-bottom: 10px;
}
.header-bar + .Login {
  padding-left: 20px !important;
}
/*==========  Mobile First  ==========*/

// ≤576px
@include media-breakpoint-up(sm) {
}

// ≤768px
@include media-breakpoint-up(md) {
  body {
    transition: all 100ms linear;
    // padding-left: 302px;
  }
  .footer {
    position: inherit !important;
    .f-title {
      min-width: 400px;
    }
  }
}

// ≤992px
@include media-breakpoint-up(lg) {
}

// ≤1200px
@include media-breakpoint-up(xl) {
}


