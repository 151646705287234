@mixin border($color) {
	border: 1px solid $color !important;
    border-radius: 3px;
}

// =placeholder{
// 	::-webkit-input-placeholder 
// 		@content

// 	::-moz-placeholder
// 		@content

// 	::-ms-input-placeholder      
// 		@content  
// }
@mixin icon($width,$height,$name){
	display: block ;
	text-indent: -9999px;
	width: #{$width}px;
	height: #{$height}px;
	background-color: $text;
	-webkit-mask-image: url(../../svg/#{$name}.svg);
	mask-image: url(../../svg/#{$name}.svg);
	// background: url(../svg/#{$name}.svg);
	mask-size: contain;
	mask-position: center;
	mask-repeat: no-repeat;
}