@import "vars";
@import "fonts";
@import "libs";
@import "_mixins/_common";
@import "admin";
@import "new";

::placeholder {
  color: #666;
}

::selection {
  background-color: $accent-dark;
  color: #fff;
}

.logo-width {
  width: 320px;
  margin-right: 50px;
}

textarea,
.input {
  resize: none;
  width: 100%;
  height: 50px;
  padding: 0 25px 0 55px;
  border: 1px solid $darkblue;
  border-radius: 3px;
  &:hover,
  &:active,
  &:focus {
    border-color: #{inherit + "88"};
  }
  ::placeholder {
    font-size: 12pt;
    color: $gray;
  }
}

html {
  overflow-y: scroll;
}
body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: $default-font;
  overflow-x: hidden;
  color: $text;
  input,
  textarea {
    border: #666 1px solid;
    outline: none;
    &:focus:required:invalid {
      border-color: red;
    }
    &:required:valid {
      border-color: green;
    }
  }
  main {
    padding: 19px 35px 40px 122px;
    min-height: 500px;
    display: flex;
    justify-content: center;
    transition: all 100ms linear;
  }
  .header-bar {
    height: 64px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 0.5px solid #e4e4e4;
    padding-left: 122px;
    padding-right: 35px;
    // position: fixed;
    transition: all 100ms linear;
    background-color: #fff;
    z-index: 1999;
    width: 100%;
    & > div {
      display: flex;
      padding-right: 20px;
      padding-left: 20px;
      flex: 1;
    }
    .title {
      font-size: 16pt;
      width: 150px;
      line-height: 1.4;
      height: 64px;

      color: $darkblue;
      font-weight: bold;
    }

    * {
      color: $darkblue;
      font-size: 11px;
      display: flex;
      align-items: center;
    }
    .right-header-block {
      * {
        color: $text;
        margin-right: 14px;
        font-size: 16px;
      }
      .user-button {
        color: $black;
      }
    }
  }
  .nowrap {
    flex-flow: nowrap;
    .find {
      width: -webkit-fill-available;
    }
  }
  .fill-avaailable {
    width: -webkit-fill-available;
  }
  divider {
    display: flex;
    height: 1px;
    border-bottom: $border-gray;
    margin-bottom: 14px;
  }
  .AddAccount {
    .FIO {
      border: 1px solid $darkblue;
      border-radius: 100px;
      padding: 5px 20px;
    }
  }
}
.SideNavToggle {
  &:hover {
    background-color: #e4e4e4;
  }
}
.sidenav---sidenav---_2tBP {
  position: fixed !important;
  z-index: 2000 !important;
  background-color: white !important ;
  border-right: 1px solid #e4e4e4;

  &:first-child {
    &::before {
      z-index: 100000000;
      content: "Меню";
      color: #afafaf;
      position: absolute;
      margin-top: 40px;
      margin-left: 10px;
      pointer-events: none;
    }
  }

  .NavItem {
    &:hover {
      background-color: #e8e8e8;
    }
  }
  .sidenav---navtext---1AE_f {
    color: $text !important;
  }
  .sidenav---navicon---3gCRo {
    * {
      background-color: $text !important;
    }
  }

  .sidenav---icon-bar---u1f02 {
    background-color: #807e7e !important;
  }
}

.Login + .header-bar {
  padding-left: inherit;
}
.Login {
  display: flex;
  justify-content: center;
  // margin-top: 60px;
  .content {
    flex-direction: column;
    display: flex;
    max-width: 650px;
    margin-top: 100px;
    p {
      font-size: 13pt;
      // font-family: "roboto-light";
      margin-bottom: 8px;
    }
    text-align: center;
    .overLine {
      h1 {
        font-family: "roboto-light";
        text-align: center;
        margin-bottom: 20px;
        p {
          font-size: inherit;
          font-family: "roboto-example" !important;
          font-weight: bold;
          color: $darkblue;
        }
      }
      text-align: center;
      border-bottom: 1px solid $gray;
      padding-bottom: 20px;
    }
    .underLine {
      margin-top: 20px;
      text-align: center;
      // text-align: center;
      p {
        // max-width: 300px;
        text-align: center;

        // margin: 0 auto;
      }
      .ButtonBlock {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        align-items: center;
        .register {
          width: max-content;
          color: $accent-dark;
          margin-top: 5px;
          &:hover {
            text-decoration: underline;
          }
        }
        input {
          margin-right: 0;
          max-width: 300px;
          min-width: 300px;
          border: $border-dark;
          margin-bottom: 10px;
          height: 40px;
          padding: 15px 20px;
          border-radius: 5px;
          transition: all 300ms ease-in-out;

          &::placeholder {
            color: $gray;
          }
        }
        button {
          background: $accent-dark;
          border: $border-dark;
          height: 40px;
          max-width: 300px;
          border-radius: 5px;
          color: $white;
          transition: all 300ms ease-in-out;
          &:hover,
          &:active,
          &:focus {
            border-radius: 20px;
          }
          &:active,
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.notAccepted {
  color: $darkgray;
}

.ChainToDialog {
  width: max-content;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 5px;
  margin-left: auto;
  cursor: pointer;
  p {
    margin: 0;
    color: $darkblue;
    &:hover {
      text-decoration: underline;
    }
  }
  .chain {
    height: 20px;
    width: 25px;
    background: $darkblue;
    position: relative;
    top: -2px;
  }
}
.radio {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  margin: 15px;
  .label {
    position: relative;
    display: block;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 21px;
    height: 21px;
    border: 2px solid #c8ccd4;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
    &:after {
      content: "";
      position: absolute;
      width: 11px;
      height: 11px;
      border-radius: 100%;
      background: $darkblue;
      transform: scale(0);
      transition: all 0.2s ease;
      opacity: 0.08;
      pointer-events: none;
    }
  }
  &:hover .label:after {
    transform: scale(3.6);
  }
}
input[type="radio"]:checked + .label {
  border-color: $darkblue;
  &:after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }
}

.hidden {
  display: none;
}
.credit {
  position: fixed;
  right: 20px;
  bottom: 20px;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0.6;
  img {
    width: 72px;
  }
  &:hover {
    transform: scale(0.95);
  }
}

////////////////////
.text-center {
  text-align: center;
}
b,
strong {
  font-weight: bold;
}
h1 {
  font-size: 24pt;
  margin-bottom: 5px;
  font-weight: bold;
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none !important;
    &:hover {
      .caret-down {
        background-color: $darkblue;
      }
    }
    .caret-down {
      transform: rotate(90deg);
      background-color: #2980b9;
    }
  }
}
h2 {
  font-weight: bold;
  font-size: 18pt;
}
.header {
  display: block;
  margin-bottom: 35px;

  .circle {
    display: flex;
    height: 50px;
    width: 50px;
    position: relative;
    left: -56px;
    top: -1px;
    left: -1px;
  }
  input[type="text"] {
    position: relative;
    top: -49px;
    border: none;
    height: 47px;
    // border: none
    width: 100%;
    max-width: 600px;
  }

  .logo-block {
    display: flex;
    justify-content: space-between;
    height: auto;
    text-align: end;
    position: relative;
    left: 0px;
  }
  .logo-text {
    color: $darkblue;
    line-height: 1.2;
    font-size: 2rem;
    cursor: default;
  }
  .w {
    width: 270px;
  }

  .left-thing {
    background-color: $darkblue;
    width: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.bordered {
  @include border($blue);
}
.menu-container {
  display: none;
  .help {
    height: 18px;
    background: $text;
  }
  .plus {
    height: 20px;
  }
  .info {
    height: 18px;
  }
  .faq {
    height: 23px;
  }
  .man {
    height: 23px;
  }
  .conversation {
    height: 23px;
  }
  .users-questions {
    height: 23px;
  }
  .answers {
    height: 23px;
  }
  .hat {
    height: 18px;
  }
  .accounts {
    height: 20px;
  }
  .add-user {
    height: 18px;
  }
}
.NavItem {
  & > div {
    &:first-child {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    &:last-child {
      padding-right: 20px;
    }
  }

  .tie {
    height: 20px;
    background-color: #717171 !important;
  }
  .operator {
    height: 28px;
    background-color: #333 !important;
  }
  .help {
    height: 18px;
    background: $white;
  }
  .plus {
    height: 20px;
    background: $white;
  }
  .info {
    background: $white;
    height: 18px;
  }
  .faq {
    background: $white;
    height: 23px;
  }
  .man {
    background: $white;
    height: 23px;
  }
  .conversation {
    background: $white;
    height: 23px;
  }
  .users-questions {
    background: $white;
    height: 23px;
  }
  .answers {
    background: $white;
    height: 23px;
  }
  .hat {
    background: $white;
    height: 18px;
  }
  .accounts {
    background: $white;
    height: 20px;
  }
  .add-user {
    background: $white;
    height: 18px;
  }
}
.max-width {
  max-width: 230px;
}
.customSelect {
  width: 300px;
}
.menu-container {
  button {
    cursor: pointer;
  }
  button:hover {
    div {
      background-color: $white;
    }
  }
  font-size: 11pt;
  & img {
    width: 15px;
    height: 15px;
    resize: both;
  }
  span {
    width: 145px;
    text-align: left;
  }
  div {
    width: 44px !important;
    align-self: center;
    margin: auto 0;
    position: relative;
    left: -8px;
  }
  & p {
    display: flex;
    margin: auto 10px;
    text-align: left;
    line-height: 19px;
  }
  & > a {
    text-decoration: none;
    &:first-child {
      button {
        margin-top: 42px;
      }
    }
    & > button {
      background: $white;
      box-shadow: none;
      outline: none;
      min-height: 50px;
      width: 100%;
      // padding: 0;
      flex-direction: row;
      display: flex;
      padding-right: 22px;
      margin: 25px auto;
      // transition: border-radius 400ms ease-in-out;
      @include border($blue);
      &:hover {
        // border-radius: 3px;
        background-color: $blue;
        color: $white;
      }
    }
  }
}
.template {
  display: flex;
  flex-direction: column;
  &* {
    display: flex;
  }
}

.icon-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  & .img {
    display: flex;
    margin-right: 30px;
  }
}

@each $icon in $icon_arr {
  .#{$icon} {
    @include icon(40, 40, #{$icon});
  }
}
.help {
  background-color: $gray;
}
.calendar {
  background-color: $yellow;
}
.waiting {
  background-color: $yellow;
}
.ruble {
  background-color: $yellow;
}
.time {
  background-color: $blue;
}
.open {
  background-color: $darkblue;
}
.good {
  background-color: $green;
}
.bad {
  background-color: $red;
}
.plus {
  background-color: $darkblue;
}
.login {
  background-color: $darkblue;
}
.search {
  width: 25px;
  height: 25px;
  margin: auto;
  background-color: $darkblue;
}
.cancel {
  background-color: $gray;
}
.caret-down {
  width: 18px;
  height: 18px;
  background-color: $darkblue;
}
.edit {
  background-color: $darkblue;
}
.internet {
  background-color: $darkblue;
}
.arrow-up {
  background-color: $darkblue;
}
.doc {
  background-color: $darkblue;
}

.darkblue {
  color: $darkblue;
}
.white {
  color: $white;
  background: $gray;
}
.blue {
  color: $blue;
}
.gray {
  color: $gray;
}
.text {
  color: $text;
}
.green {
  color: $green;
}
.yellow {
  color: $yellow;
}
.red {
  color: $red;
}
.texthover:hover {
  background: $texthover;
  color: $text;
}

#login,
#user,
#admin,
#controller,
#expert {
  min-height: 100vh;
  // padding-bottom: 150px;
  & > .container {
    margin-bottom: 80px;
    .h1 {
      border-bottom: $gray-border;
      // background: #007bff;
    }
  }
}

.gray-border {
  border-bottom: 1px solid #eee;
}
.Help {
  width: 100%;
  .accordion {
    width: 100%;
    // padding-right: 26px;
    .card {
      background: $white;
      box-shadow: none;
      outline: none;
      min-height: 50px;
      width: 100%;
      // padding: 0;
      flex-direction: column;
      display: flex;
      margin: 25px auto;
      border-radius: 3px !important;
      // transition-duration: 0.2s;
      @include border($blue);
      .card-body {
        p {
          font-size: 11pt;
        }
      }
      &:first-child {
        margin-top: 0px;
      }
      .card-header > a,
      h5 {
        color: $black;
        text-decoration: none !important;
        margin: 0;
        white-space: pre-wrap;
        text-align: left;
        font-size: 12pt;
      }
      & h5,
      & .btn-link {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
      }
      .btn-link {
        border-radius: 0;
        border-bottom: 1px solid $blue;
        min-height: 48px;
        transition-duration: 200ms;
        text-decoration: none;
        .caret-down {
          transform: rotate(180deg);
          transition: transform 300ms ease-in-out;
          :hover {
            background: $darkblue;
          }
        }
        &.collapsed {
          .caret-down {
            transform: rotate(0deg);
          }
          border-color: transparent;
        }
      }
      .card-header {
        background: $white !important;
        padding: 0;
        border: none;
        line-height: 40px;
        // margin: 0 1.25em;
      }
    }
  }
}
.AllDialogs {
  width: 100%;
}

table {
  tr {
    &:nth-child(2n) {
      background: $texthover;
    }
    // &:hover {
    //   background: $hover_gray;
    // }
    &:hover {
      cursor: default;
    }
  }
}

.Search {
  width: 100%;
  display: flex;
  flex-direction: row;
  @include border($darkblue);
  .circle {
    .search {
      height: 18px;
      width: inherit;
    }
    display: flex;
    height: 40px;
    top: -1px;
    width: 40px;
    border-radius: 3px;
    position: relative;
    border-left: transparent;
    &:hover {
      transition: all 150ms ease-in-out;
      // background-color: $darkblue;
      // .search {
      //   background-color: $white;
      // }
      cursor: pointer;
    }
  }
  input {
    width: 100%;
    margin: auto 10px auto 0;
    border: none;
    &::placeholder {
      color: $gray;
    }
  }
}

.Users {
  width: 100%;
  .categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // max-width: 200px;
    .category {
      background-color: #f2f2f2;
      font-size: 11pt;
      width: fit-content;
      border-radius: 3px;
      margin: 5px 10px 5px 0;

      padding: 3px 15px;
    }
  }
  .UsersTable {
    width: 100%;
    .UserRow {
      background: $white;

      td:nth-child(2) {
        width: 250px;
      }
      td:nth-child(3) {
        padding-left: 0;
        .typeBlock {
          justify-content: left;
        }
      }
      td:nth-child(5),
      td:nth-child(6),
      td:nth-child(4) {
        width: 20px;
      }
      &:hover {
        background: $hover_gray;
      }
      .typeBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .hat {
          width: 25px;
          height: 15px;
          position: relative;
          left: -2px;
        }
      }
    }
    .index {
      color: $text;
      font-size: 11pt;
      width: 20px;
    }
    td {
      color: $text;
      padding: 20px 10px;
      border-top: 1px solid $gray;
      border-bottom: 1px solid $gray;
    }
    th {
      font-weight: normal;
      color: #{$text + "cc"};
      padding: 0px 0px 0px 7px;
    }
    .edit {
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:hover {
        background: $blue;
      }
    }
    .login {
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:hover {
        background: $blue;
      }
    }
    .icon-container {
      width: 20px;
      height: 20px;
      border: 1px solid $gray;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      display: flex;
      cursor: pointer;
      &:hover {
        border-color: $red;
        .cancel {
          background: $red;
        }
      }
    }
    .cancel {
      background: $gray;
      width: 8px;
      height: 8px;
    }

    thead {
      td {
        border: none !important;
        padding: 5px 0;
        padding-left: 10px;
      }
      color: $gray;
      // &:hover {
      //   background: $white;
      // }
    }
  }
}

.Paginator {
  display: flex;
  margin: 30px 0;
  flex-direction: row;
  flex-wrap: wrap;
  .PaginatorElement {
    border: 1px solid $darkblue;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 15px;
    &:hover {
      background: $darkblue !important;
      color: $white !important;
    }
  }
}
.User {
  flex-direction: column;
  display: flex;
}
.btn {
  &.green {
    color: $darkgreen !important;
    border-color: $darkgreen !important;
    &:hover {
      background: $darkgreen !important;
      color: $white !important;
    }
  }
}
.CreateFAQ .CreateForm .btn.green {
  color: $darkgreen !important;
  &:hover {
    color: $white !important;
  }
}
.AddUser,
.User {
  width: 100%;
  .css-1pahdxg-control {
    box-shadow: none !important;
    border-color: $darkblue !important;
    &:active,
    &:focus {
      border-color: $blue !important;
    }
  }
  .css-yk16xz-control {
    border-color: $darkblue !important;
    outline-color: transparent !important;
  }
  .css-26l3qy-menu {
    //выпадалка
    // border-radius:/* 0 0 25px*/ 25px;
    border: $border-dark;
    border-width: 1px;
    margin-top: 5px;
  }
  input[type="text"] {
    border: none;
    border-bottom: 1px solid $darkblue;
    height: 35px;
    width: 280px;
    line-height: 50px;
    &::placeholder {
      color: $darkgray;
    }
  }
  input[type="submit"],
  .btn {
    color: $accent-dark;
    border: 1px solid $accent-dark;
    border-radius: 3px;
    background: $white;
    height: 50px;
    width: $button-width;
    cursor: pointer;
    &:hover {
      background: $darkblue;
      color: $white;
    }
  }
  .btn {
    padding: 0;
    a {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: $darkblue;
      text-decoration: none !important;
      &:hover {
        color: $white;
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    label {
      p {
        width: 100%;
        margin-bottom: 0;
        float: left;
        line-height: 35px;
      }
      .radio {
        margin-left: 0;
        margin-top: 0;
      }
    }

    .categories {
      max-width: 500px;
      border-color: $border-gray;
      margin-bottom: 10px;
      .css-1pahdxg-control {
        border-color: transparent;
      }
    }
    // .categories {
    //   margin-top: 15px;
    //   margin-bottom: 30px;
    //   max-width: 1000px;
    //   height: 40px;
    //   & > .css-yk16xz-control {
    //     height: 40px;
    //     & > div {
    //       height: 40px;
    //     }
    //     .css-1wa3eu0-placeholder {
    //       position: unset;
    //       top: unset;

    //     }
    //   }
    //   & > .css-1pahdxg-control {
    //     height: 40px;
    //     & > div {
    //       height: 40px;
    //     }
    //   }
    //   input {
    //     height: 40px;
    //     line-height: unset;
    //   }
    // }
  }
}

.QArea {
  height: 200px;
  margin-top: 15px !important;
  padding: 10px 20px 35px 20px;
  border: 1px solid #d6d6d6 !important;
}
.CreateQuestion {
  width: 100%;
  .h_undrln {
    border-bottom: 1px solid $gray;
    padding-bottom: 3px;
  }
  .categories {
    margin-bottom: 30px;
    max-width: 300px;
    border-radius: 3px;
    border: $border-dark;
    outline: none;

    .css-1pahdxg-control {
      box-shadow: none !important;
      border-color: transparent !important;
      &:active,
      &:focus {
        border-color: transparent !important;
      }
    }
    .css-yk16xz-control {
      border-color: transparent !important;
      outline-color: transparent !important;
    }
    .css-26l3qy-menu {
      //выпадалка
      // border-radius:/* 0 0 25px*/ 25px;
      border: $border-dark;
      border-width: 1px;
      margin-top: 5px;
    }
  }
  .qTip {
    font-size: 12pt;
    margin-bottom: 5px;
  }
  .QArea {
    height: 200px;
    margin-top: 15px;
    padding: 10px 20px;
    border: 1px solid $darkblue !important;
  }
  .lettersCount {
    position: relative;
    width: calc(100% -10px);
    background-color: #fff;
    text-align: right;
    color: $darkblue;
  }
  .send {
    position: relative;
    top: -27px;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 5px;
    width: 150px;
    text-decoration: none;
    padding-left: 15px;
    padding-right: 20px;
    border: 1px solid $darkblue;
    color: $darkblue;
    background: $white;
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .letter {
      float: left;
      background: $darkblue;
      height: 15px;
      position: relative;
      top: 1px;
    }

    &:hover {
      color: $white;
      background: $darkblue;
      .letter {
        background: $white;
      }
    }
  }
}
.withUnderline {
  &:hover {
    text-decoration: underline;
  }
}
.DialogsTable {
  width: 100%;
  margin-top: 10px;

  thead {
    tr {
      td:nth-child(3) {
        .block {
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
  .index {
    color: $gray;
    width: 40px;
    text-align: center;
  }
  .colName {
    .title {
      color: $darkgray;
    }
    .block {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $darkgray;
      margin-bottom: 5px;
      font-size: 10pt;
    }
    .help {
      cursor: pointer;
      height: 16px;
      width: 21px;
      transition-duration: 400ms;
      background: $gray;
    }
  }
  .Row {
    border-top: 1px solid $gray;
    height: 60px;
    &:last-child {
      border-bottom: 1px solid $gray;
    }
    .status {
      width: 50px;
      * {
        height: 30px;
      }
    }
    td {
      // border: $gray-border !important;
      margin: 15px 0;
      font-size: 11pt;
    }
    .text {
      .important {
        color: $red;
        font-size: 16pt;
        margin-right: 10px;
        text-decoration: none !important;
      }
      // min-width: 50%;
      a {
        max-height: 60px;
        max-width: 400px;
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none !important;
        color: #18567e !important;
        align-items: center;
      }
    }
    .categoryTitle {
      font-size: 10pt;
      text-align: center;
      width: 180px;
    }
    .timeMsg {
      width: 120px;
      font-size: 10pt;
      color: #707070;
    }
    .timeBlock {
      .timeRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .element {
          min-width: 68px;
          font-size: 9pt;
        }
      }
      .calendar,
      .time {
        height: 15px;
      }
    }
  }
}
.Questions {
  width: 100%;
  .summary {
    background-color: #fff !important;
  }
}

.MessageBlock {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $light-gray;
  // border-bottom: 1px solid $gray;
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
  min-height: 120px;
  padding: 20px 10px 10px 0;
  .createTime {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100% !important;
    padding-right: 5px;
    .timeRow {
      display: flex;
      flex-direction: row;
      font-size: 10pt;
      color: $darkgray;
      align-items: center;
      .element {
        width: 75px;
      }
      .calendar,
      .time {
        height: 14px;
      }
    }
  }
  .text {
    flex: 1;
    padding-left: 10px;
    font-size: 11pt;
    min-height: 60px;
  }
  .message {
    min-height: 60px;
    overflow: hidden;
    // border-right: 4px solid $darkblue;
  }
  .read-more-button {
    cursor: pointer;
    text-align: right;
    color: $darkblue;
  }
  .avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 10px;
    margin-top: 10px;
    height: max-content;
    .userName {
      &:before {
        content: "Имя пользователя";
        color: $gray;
        font-size: 13px;
        font-weight: 200;
        line-height: 120%;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: bold;
      margin-top: 3px;
      font-size: 16px;
      width: min-content;
      text-align: center;
      line-height: 130%;
    }
    .circle {
      background: $orange;
    }
    .icon,
    .circle {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;

      &.question {
        background-color: $light-blue;
        color: $white;
        font-size: 16pt;
      }
      &.hat {
        background-color: $white;
        height: 20px;
      }
    }
  }
}

.Question {
  width: 100%;
  .MessageBlock {
    border-bottom: 1px solid $light-gray;
    border-top: none;
    &:nth-child(2) {
      border-top: 1px solid $light-gray;
    }
  }
}

.Dialog {
  width: 100%;
  .caret-down {
    transform: rotate(90deg);
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }
  .DialogTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .Messages {
    margin-bottom: 20px;
  }
}
.Messages {
  max-height: 400px;
  overflow-y: auto;
  // padding-right: 20px;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;

  .MessageBlock {
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      border-top: none;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: $hover_gray;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $darkblue;
  }
}
.TitleRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  h1 {
    flex: 1;
    min-width: 100%;
  }
  .Search {
    display: flex;
    align-self: flex-end;
    width: 300px;
    height: 40px;
    .circle {
      // display: none;
      height: 40px;
      top: -1px;
    }
  }
}
.btn-block {
  flex-direction: row;
  display: flex;
  flex-direction: column;
  .dialog-btn {
    cursor: pointer;
    height: 40px;
    width: 250px;
    padding: 0 40px;
    text-decoration: none;
    text-align: center;
    background-color: $white;
    color: $darkblue;
    outline: none;
    border: 1px solid $darkblue;
    border-radius: 3px;
    margin-bottom: 8px;

    &.disabled {
      border: 1px solid #d6d6d6;
      color: #d6d6d6;
      cursor: default;
      .letter {
        background-color: #d6d6d6 !important;
      }
      &:hover {
        border-color: #d6d6d6 !important;
        background-color: $white !important;
        color: #d6d6d6 !important;
        .letter {
          background-color: #d6d6d6 !important;
        }
      }
    }
    &.filled {
      border-color: #3bb1ff;
      background-color: #3bb1ff;
      color: $white;
      &:hover {
        border-color: #19a4ff !important;
        background-color: #19a4ff !important;
      }
      .letter {
        background-color: $white !important;
      }
    }

    &.btn-dialog-close {
      border-color: $darkgreen;
      color: $darkgreen;
      &:hover {
        background-color: $darkgreen;
        border-color: $white;
        color: $white;
      }
    }
    &.btn-send {
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center;
      .letter {
        width: 30px;
        position: relative;
        left: -5px;
        height: 14px;
        background-color: $darkblue;
      }
      &:hover {
        background-color: $darkblue;
        color: $white;
        .letter {
          background-color: $white;
        }
      }
    }
    &:hover {
      background-color: $darkblue;
      color: $white;
    }
  }
}
.ModalDialog {
  background-color: $white;
  border: 1px solid $darkblue;
  border-radius: 5px;
  min-height: 370px;
  min-width: 310px;
  padding: 40px 0 50px 0;
  h3 {
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }
  .btn {
    background: $green;
    color: $white;
    border: 1px solid $green;
    padding: 7px 20px;
    margin-bottom: 8px;
    float: right;
    width: 100%;
    clear: both;
    border-radius: 3px;

    &:hover,
    &:active,
    &:focus {
      background: #{$green + "ee"};
    }
  }
  .input {
    min-height: 100px;
    padding: 10px 20px;
    &:hover {
      border-color: $darkblue !important;
    }
  }
  .close-button {
    position: absolute;
    right: 22px;
    top: 22px;
    background-color: $text;
    height: 10px;
    width: 10px;
    cursor: pointer;
    &:hover {
      background-color: $red;
    }
  }
  .Stars {
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
    margin: 10px 0;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    clear: both;
    .tip {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      clear: both;
      text-align: center;
    }
    .starsBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .Star {
        margin-right: 5px;
        cursor: pointer;
        &.star,
        &.star-filled {
          width: 20px;
          height: 35px;
        }
        &.star-filled {
          background-color: #f1d14e;
        }
        &.star {
          background-color: $darkblue;
        }
      }
    }
  }
}
.AnotherFAQHeader {
  margin-top: 20px;
}
.FaqElement {
  border-top: 1px solid $gray;
  margin: 0;
  padding: 18px 0;
  a {
    color: $darkblue;
  }
  span {
    text-decoration: none;
  }
  &:last-child {
    border-bottom: 1px solid $gray;
  }
}
textarea {
  max-width: 600px;
  margin-top: 20px;
}
.ClosedDialogBlock {
  .commonText,
  .Bad,
  .Good {
    font-size: 12pt;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }
  .commonText {
    font-size: 12pt;
    // border-bottom: 1px solid $gray;
    .check {
      height: 15px;
      background: $darkgreen;
    }
  }
  .Bad,
  .Good {
    min-height: 50px;
    font-size: 12pt;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }
  .Bad {
    border-color: $red;
  }
  .Good {
    border-color: $green;
  }
}
.DialogTitle {
  font-size: 16pt;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
  margin-bottom: 0;
  .caret-down {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }
}

.CreateFAQ {
  width: 100%;
  .Messages {
    height: 300px;
  }
  .CreateForm {
    margin-top: 20px;
    .red,
    .green {
      color: $text !important;
    }
    .red {
      border-color: $red !important;
    }
    .green {
      border-color: $green !important;
    }
    textarea {
      margin-top: 0;
    }
    .TextArea {
      margin-top: 10px;
      .title {
        margin-bottom: 2px;
        font-weight: bold;
        position: relative;
        left: 25px;
      }
      textarea {
        height: 150px;
        padding: 10px 25px;
        &:hover,
        &:focus,
        &:active {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
      input {
        width: 100%;
        max-width: 600px;
        border: 1px solid $darkblue;
        padding: 5px 25px;
        border-radius: 3px;
        &:hover,
        &:focus,
        &:active {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
    }
    .btn {
      span {
        height: 25px;
      }
      width: 200px;
      height: 45px;
      margin-top: 10px;
      background: $white;
      color: $text;
      border: 1px solid $darkblue;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      &:hover,
      &:focus,
      &:active {
        background: $darkblue;
        color: $white;
      }
    }
  }
}

.FAQs {
  width: 100%;
  .categories {
    width: 250px;
  }
  .DialogsTable .Row .text a {
    max-width: 800px;
  }
}
.FAQ {
  width: 100%;
  .delete {
    border: 1px solid $gray;
    border-radius: 4px;
    color: $text;
    padding: 10px 15px;
    text-decoration: none;
    transition-duration: 200ms;
    position: relative;
    // top: 21px;
    &:hover {
      border-color: $red;
      background-color: $red;
      color: $white;
    }
  }
  .Title {
    .h3 {
      font-weight: bold;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 5px;
    .categories {
      max-width: 300px;
      min-width: 300px;
      flex: 1;
    }

    .iconBlock {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: flex-end;
      margin-top: 5px;
      margin-left: auto;
      justify-content: center;
    }
    .block {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 30px;
      &:last-child {
        margin-left: 20px;
      }
      .calendar,
      .time {
        height: 17px;
        width: 25px;
        // margin-left: 10px;
      }
      .time {
        margin-right: 2px;
      }
      .text {
        font-size: 10pt;
        color: #707070;
      }
    }
  }
  .content {
    width: 100%;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    .Answer,
    .Question {
      min-height: 120px;
      padding: 20px 0;
      span {
        color: #888;
        margin-right: 10px;
      }
    }
    .Answer {
      border-top: 1px solid $gray;
    }
  }
  .Rule {
    margin-top: 10px;
    font-size: 12pt;
    font-weight: bold;
    color: $darkblue;
    span {
      font-weight: normal;
      color: $text;
      margin-right: 10px;
    }
  }
}
.EFAQ {
  .Answer,
  .Question,
  .Rule {
    min-height: 120px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    textarea {
      margin-top: 0;
      min-height: 80px;
      height: max-content;
      padding: 15px 15px 15px 20px;
    }
  }
}
.DialogHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  .title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 500px;
    margin-bottom: 10px;
  }
  .category {
    text-align: end;
    margin-bottom: 5px;
    width: fit-content;
    // width: 300px;
  }
}

.AcceptBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  p {
    margin: 0;
    color: $darkgreen;
  }
  .check {
    height: 11px;
    width: 25px;
    background: $darkgreen;
  }
}

.FormForQuotedMessage {
  // position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  .qMsg {
    text-align: start;
  }
  textarea {
    padding-top: 10px;
    padding-left: 20px;
    min-height: 120px;
  }
  a {
    max-width: 280px;
    border-radius: 3px;
    border: 1px solid $darkblue;
    color: $darkblue;
    transition-duration: 200ms;
    margin-top: 5px;
    padding: 7px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    .letter {
      height: 15px;
      background-color: $darkblue;
    }
    &:hover {
      background-color: $darkblue;
      color: $white;
      .letter {
        background-color: $white;
      }
    }
  }
}
.QMessage {
  font-size: 11pt;
  border-left: 1px solid $gray;
  padding-left: 15px;
  margin-left: 5px;
  margin-top: 5px;
  .qoute {
    color: $darkgray;
  }
}

.react-tooltip-lite {
  background: $white !important;
  border-radius: 4px;
  border: 1px solid $darkblue;
  // height: 30px;
}
.react-tooltip-lite-arrow {
  // display: none;
  z-index: 1002 !important;
  border-bottom-width: 5px !important;
}

.summary {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px 8px 10px 8px;
  border-top: 1px solid $light-gray;
  &:last-child {
    border-bottom: 1px solid $light-gray;
  }
  // &:hover {
  //   cursor: pointer;
  //   background-color: #333;
  // }

  .topRow {
    flex: 1;
    display: flex;
    .leftBlock {
      .status {
        float: left;
        // width: 30px;
        position: relative;
        top: 10px;
      }
    }

    .qBlock {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      .bottomRow {
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        .categoryTitle {
          width: fit-content;
          max-width: 250px;
          padding: 5px 8px;
          background-color: #f2f2f2;
          text-decoration: none;
          color: $text;
          border-radius: 5px;
          font-size: smaller;
          cursor: text;
          float: left;
        }
        .timeBlock {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          // float: right;
          width: max-content;
          margin-left: auto;
          .element {
            align-items: center;
            display: flex;
            margin: 0 0 0 5px;
            color: $darkgray;
            font-size: smaller;
          }
          .timeRow {
            display: flex;
            flex-direction: row;
            &:last-child {
              margin: 0 0 0 15px;
            }
            .calendar,
            .time {
              width: 23px;
            }
          }
        }
      }

      .text {
        display: flex;
        padding-left: 5px;
        flex: 1;
        &:hover {
          cursor: pointer;
          color: $darkblue;
        }
        .important {
          color: $red;
          font-size: 16pt;
          margin-right: 10px;
          text-decoration: none !important;
        }

        a {
          color: #0077cc;
          flex: 1;
          text-decoration: none;
          padding-top: 5px;
          padding-bottom: 5px;
          &:hover {
            color: $darkblue;
          }
          span {
            text-decoration: none;
          }
        }
      }
    }
  }
}
.time,
.calendar {
  width: 25px;
}

.FAQ-button {
  border: 1px solid $darkblue;
  color: $darkblue;
  transition-duration: 200ms;
  padding: 10px 20px;
  position: relative;
  margin-top: 20px;
  border-radius: 3px;
  text-decoration: none !important;
  &:hover {
    background-color: $darkblue;
    color: $white;
  }
}
.errorMessage {
  background-color: #{$yellow + "22"};
  margin: 10px 10px;
  padding: 10px 0;
}
.b-r-3 {
  border-radius: 3px !important;
}
.b-r-0 {
  * {
    border-radius: 0px !important;
  }
  border-radius: 0px !important;
}
.fixed-t-64 {
  width: 100%;
  position: fixed;
  top: 64px;
  z-index: 1999;
  background-color: #fff;
}
.ellipsis {
  display: block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.EmptyMessage {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  .empty {
    height: 150px;
    width: 150px;
    background-color: $blue;
  }
  .message {
    color: $darkblue;
    text-align: center;
  }
}

.UserRowMobile {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid $light-gray;
  display: flex;
  flex-direction: column;
  &:last-child {
    border-bottom: 1px solid $light-gray;
    padding-bottom: 10px;
  }
  .firstName {
    font-weight: bold;
    font-size: 14pt;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .cancel {
    height: 20px !important;
    width: 20px !important;
    &:hover {
      background-color: $red;
    }
  }
  .userType {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
  }
  .tie {
    height: 20px;
    width: 28px;
  }
  .hat {
    height: 20px;
  }
}
.Users {
  max-width: 800px;
  margin: auto;
}
.loginButton {
  max-width: 300px;
  min-width: 300px;
  border-radius: 3px;
  border: 1px solid $darkblue;
  color: $white;
  background-color: $darkblue;
  transition-duration: 200ms;
  margin-top: 5px;
  padding: 7px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  &:hover {
    color: $white;
    background-color: #{$darkblue + "ee"};
  }
}

.NotFound {
  color: $blue;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  .warning {
    background-color: $blue;
    height: 200px;
    width: 200px;
  }
}
.all-w-border {
  border: none !important;
}
.similarQuestions {
  margin-bottom: 0;
}
.anotherQuestion {
  margin-top: 8px !important;
}
.CreateQuestion {
  .DialogsTable {
    margin-top: 0;
    .summary:first-child {
      border-top: none;
    }
  }
}
.margin-v-15 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.border-red {
  border-color: $red !important;
}
.hover-red {
  background-color: $white !important;
  color: $red !important;
  a {
    color: $red !important;
  }
  &:hover {
    background-color: $red !important;
    color: $white !important;
    a {
      color: $white !important;
    }
  }
}
.dialogClosed {
  display: flex;
  flex-direction: row;
  align-items: center;
  .check {
    background-color: $green;
    height: 16px;
  }
  p {
    margin-bottom: 0;
  }
}
.footer {
  width: 100%;
  background: $darkblue;
  position: relative;
  bottom: 0;
  margin-top: 40px;
  z-index: 10000;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    // flex-direction: row;
  }
  .f-col {
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;
  }
  .f-el {
    padding: 0 15px;
    color: $white;
    justify-content: center;
    display: flex;
    margin: 2px 0;
    font-size: 14pt;
  }
  .a-r {
    display: flex;
    justify-content: flex-end;
  }

  .a-l {
    display: flex;
    justify-content: flex-start;
  }
  .logotype {
    font-size: 20pt;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    text-decoration: none;
    margin-top: 0px;
    margin-bottom: 10px;
    &:hover {
      color: #{$white + "cc"};
    }
    .logo {
      height: 200px;
      width: 200px;
      background-color: #fff;
      margin-right: 20px;
    }
  }
  .f-title {
    font-size: 16pt;
    padding-bottom: 3px;
    margin: 5px 20px 5px 20px;
    border-bottom: 1px solid $white;
    margin: 5px auto;
    min-width: 210px;
    display: flex;
    align-items: center;
    width: max-content;
    .settings {
      width: 30px;
      height: 20px;
    }
    .letter {
      // width: 30px;
      height: 15px;
    }

    .settings,
    .letter {
      background-color: #fff;
    }
  }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: #{$white + "cc"};
    }
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.FiltersBlock {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  .categories {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .category {
      width: fit-content;
      margin: 5px 40px;
      margin-left: 0;
      height: fit-content;
      padding: 5px 0px;
      text-decoration: none;
      color: $gray;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      float: left;
      &.selected {
        color: #0077cc;
        background: aliceblue;
        padding: 5px 20px;
      }
      &:hover {
        color: #0077cc;
      }
    }
  }
  .f-btn {
    font-size: 14px;
    // &:hover,
    &:active,
    &:focus {
      // color: $text !important;
    }
  }
  .filterBlock {
    display: flex;
    align-items: center;
    // width: 100%;
    flex-wrap: wrap;
    margin: 5px;
    margin-left: 0;
    // font-size: s;
    float: left;
    .isClosed {
      color: $darkblue;
      background-color: #f6f3ff;
      &.any {
        background-color: #f2f2f2;
        color: $darkblue;
      }

      &.true {
        color: #136c39;
        background-color: #f3ffef;
      }
    }
    .orderByNew {
      color: $darkblue;
    }
    .orderByNew,
    .isClosed {
      cursor: pointer;
      padding: 5px 8px;
      margin: 0 5px;
      border-radius: 5px;
      background-color: #f2f2f2;
    }
  }
  .clearFilters {
    display: flex;
    align-items: center;
    padding: 7px 8px;
    width: max-content;
    color: $darkblue;
    cursor: pointer;
    border-radius: 3px;
    .refresh {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      background-color: $darkblue;
    }
    &:hover {
      .refresh {
        animation: rotate 1s infinite ease-in-out;
      }
    }
    &:hover,
    &:active,
    &:focus {
      background-color: #ddddddee !important;
    }
  }
}

.error-login {
  margin-top: 5px;
  font-size: 11pt;
  color: $darkgray;
}
.save-btn {
  border: 1px solid $gray;
  position: relative;
  // top: 41px;
  padding: 10px 15px;
  max-width: 300px;
  transition: all 200ms ease-in-out;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // float: right;
  &:hover {
    border-color: $darkblue;
    background-color: $darkblue;
    color: $white;
  }
}
.btn-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .delete {
    margin-left: 5px;
    margin-right: 5px;
  }
  .save-btn {
    margin-left: 5px;
    margin-right: 5px;
  }
  & > * {
    margin: 10px 0;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.observation {
  background-color: $white;
  height: 20px;
}
.starsBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  .Star {
    margin-right: 5px;
    &.star,
    &.star-filled {
      width: 20px;
      height: 35px;
    }
    &.star-filled {
      background-color: #f1d14e;
    }
    &.star {
      background-color: $darkblue;
    }
  }
}
.message {
  margin-bottom: 0;
}
.ReadMore {
  display: flex;
  justify-content: flex-end;
  color: $darkblue;
  cursor: pointer;
}

.addYourQ {
  margin-top: 10px;
  display: flex;
  align-items: center;
  .href {
    display: flex;
    margin-left: 10px;
    align-items: center;
    .plus {
      transition: all 300ms ease-in-out;
      height: 15px;
      width: 15px;
      margin-right: 5px;
      background-position: -10px;
    }
    &:hover {
      .plus {
        height: 20px;
        width: 20px;
      }
    }
  }
}
.timer {
  display: flex;
  align-items: center;
  margin-left: 6px;
  font-size: 10pt;
  color: #797979;
}
.dialog-btn {
  cursor: pointer;
  height: 40px;
  width: 250px;
  padding: 0 40px;
  text-decoration: none;
  text-align: center;
  background-color: $white;
  color: $darkblue;
  outline: none;
  border: 1px solid $darkblue;
  border-radius: 3px;
  margin-bottom: 8px;
  &.btn-dialog-close {
    margin-top: 20px;
    border-color: $green;
    color: $green;
    &:hover {
      background-color: $green;
      border-color: $white;
      color: $white;
    }
  }
}
.AddCategoryForm {
  input[type="text"] {
    margin-bottom: 15px;
  }
}
.EditCategory {
  margin-top: 15px;
}
.pointer {
  cursor: pointer;
}
.CategoryList {
  div {
    &:nth-child(2n) {
      .ListElement {
        background-color: rgb(250, 253, 255);
      }
    }
  }
}
.ListElement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 10px 5px;
  input {
    background-color: transparent;
  }
  .iconBlock {
    display: flex;
  }
  .edit {
    height: 22px;
    margin-left: 25px;
    margin-right: 10px;
    width: 22px;
    &:hover {
      background-color: #{$darkblue + "aa"};
    }
  }
  .cancel {
    height: 22px;
    width: 22px;
    &:hover {
      background-color: $red;
    }
  }
  .good {
    height: 27px;
    transition: all 200ms ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}
.displayed-text {
  white-space: pre-line;
  padding-right: 20px;
}

.TextAreaBlock {
  max-width: 600px;
  .counter {
    text-align: end;
  }
  .btn-block {
    position: relative;
    top: -25px;
  }
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: $hover_gray;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  top: -15px;
  cursor: pointer;
  .block {
    padding: 3px;
    border: 1px solid $darkblue;
    border-radius: 3px;
    margin-right: 10px;
    transition: all 200ms;
  }

  .check {
    width: 17px;
    height: 17px;
  }
}

.isAnswer {
  color: $gray;
  font-size: 13px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  .check {
    height: 14px;
    width: 14px;
    margin-right: 10px;
    background-color: $gray;
  }
}

.preloader {
  background-color: $white;
  box-shadow: 0px 0px 50px #eee;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  z-index: 9999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 400ms;
  .icon {
    * {
      background-color: $darkblue;
    }
  }
  .check {
    height: 20px;
    width: 20px;
    background-color: $green;
  }
  .loader {
    height: 20px;
    width: 20px;
    animation: rotate 1.5s infinite linear;
  }
  .close-preloader {
    height: 15px;
    width: 15px;
  }
}
@keyframes load {
  0% {
    transform: rotate(0);
    // animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(360deg);
  }
}

.display-text-group {
  padding-right: 20px;
}

select {
  border: 1px solid #eee;
  min-width: 300px;
  padding: 10px 20px 14px 14px;
  margin-top: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.select-label {
  margin-left: 25px;
  font-weight: bold;
}

.norms-list {
  margin: 30px 0;

  &__title {
    font-size: 24px;
    font-weight: bold;
  }
}

.norm {
  margin: 10px 0;
  &__title {
  }
  &__actual-text {
    color: #999;
  }
  &__actuailize {
    margin-top: 10px;
    font-weight: 500;
    padding: 6px 14px 10px 14px;
    border-radius: 4px;
    transition: all 200ms linear;
    cursor: pointer;
    color: #19a4ff;
    border: 1px solid #19a4ff;
    width: fit-content;
    &:hover {
      border-color: #18567e;
      color: #18567e;
    }
  }
}

@import "media";
