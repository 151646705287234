.statistic-row {
  border: 1px solid #eee;
  padding: 10px 35px 16px 22px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #fafafa;
  }
  &::placeholder {
    color: #ccc;
  }
  .light-gray {
    color: #eee;
  }
  span {
    display: flex;
  }
  .first-name {
    color: #333;
    margin-right: auto;
    font-weight: bold;
    font-size: 20px;
  }
  .dialog-count {
    margin-right: 10px;
  }
  .dialog-closed {
    margin-right: 10px;
  }
  .success-percent {
    color: #2980b9;
    font-weight: bolder;
  }
}

.color-text {
  color: #333;
}

.accent-text {
  color: #3498db;
}
